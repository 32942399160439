import LazyLoad from 'vanilla-lazyload';

const init = () => {
  new LazyLoad({
    elements_selector: '.lazy',
    threshold: 100
  });
};

export default init;
